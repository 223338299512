<!-- Countdown.vue -->
<template>
  <span style="color: #F95A5A !important;">{{ formattedTime }}</span>
</template>

<script>
export default {
  props: {
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      remainingTime: 0,
      intervalId: null,
    };
  },
  computed: {
    formattedTime() {
      let seconds = this.remainingTime;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `剩余${hours}小时${minutes}分钟${seconds}秒`;
    },
  },
  methods: {
    calculateRemainingTime() {
      const now = new Date().getTime();
      const end = new Date(this.endTime).getTime();
      this.remainingTime = Math.max(0, Math.floor((end - now) / 1000));
      if (this.remainingTime === 0) {
        clearInterval(this.intervalId);
        this.$emit("on-end");
      }
    },
  },
  mounted() {
    this.calculateRemainingTime();
    this.intervalId = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
/* 添加你的样式 */
</style>
