<!-- 事件信息内容 -->
<template>
  <div class="">
    <a-descriptions layout="vertical">
      <a-descriptions-item label="发生时间">
        2024年7月10日10:45:41
      </a-descriptions-item>
      <a-descriptions-item label="涉及人员" :span="2">张三</a-descriptions-item>
      <a-descriptions-item label="事件描述" :span="3">
        {{ name }}</a-descriptions-item
      >
      <a-descriptions-item label="相关照片" :span="3">
        <img
          v-for="i in 9"
          src="https://ts1.cn.mm.bing.net/th/id/R-C.66d7b796377883a92aad65b283ef1f84?rik=sQ%2fKoYAcr%2bOwsw&riu=http%3a%2f%2fwww.quazero.com%2fuploads%2fallimg%2f140305%2f1-140305131415.jpg&ehk=Hxl%2fQ9pbEiuuybrGWTEPJOhvrFK9C3vyCcWicooXfNE%3d&risl=&pid=ImgRaw&r=0.png"
          style="width: 90px; height: 90px; margin: 0 2% 2%"
          alt=""
        />
      </a-descriptions-item>
    </a-descriptions>
    <div class="bottom">
      <time-line :steps="steps"></time-line>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import TimeLine from "./timeLine.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { TimeLine },
  props: ["status1", "steps"],
  data() {
    //这里存放数据
    return {
      visible: false,
      name: "事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨阿斯顿法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法件萨法",
      textarea: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-descriptions {
  border: 1px solid #e2e8f1;
  padding: 2%;
  padding-bottom: 0;
}
.bottom {
  margin-top: 1%;
}
.btn-group {
  margin-top: 2%;
}
</style>